import React, {useState, useEffect} from 'react';
import { Link } from "react-router-dom";
import { TickerTape } from "react-ts-tradingview-widgets";

  const getAmount = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'accounting'}).format(amount);

const Invest = () => {

    let theme = localStorage.getItem('REVAMP BOTS-Theme');
    if (theme === 'dark-mode') {
        theme = 'dark'
     }else{
      theme = 'light'
     }
    const [currentTheme, setCurrentTheme] = useState(theme);

    const initialPlans = {
        loading: true,
        planList: null
    }
    const [plans, setPlans] = useState(initialPlans);

    const fetchPlans = async() => {
            let plan = localStorage.getItem('plans');
            if (plan != null) {
                plan = JSON.parse(plan);
                setPlans({...plans, loading: false, planList: plan}); 
            }
               
      }
      const setCurrentPlan = (plan) => {
        localStorage.setItem('newPlan', JSON.stringify(plan));
        const planEvent = new Event('newPlan');
        window.dispatchEvent(planEvent);
      }

      useEffect(()=>{
        fetchPlans();

        window.addEventListener('themeChanged', function (e) {
             let theme = localStorage.getItem('REVAMP BOTS-Theme');
             if (theme !== null) {
                 if (theme === 'dark-mode') {
                    setCurrentTheme('dark')
                 }else{
                    setCurrentTheme('light') 
                 }
                
             }             
        }, false);

      }, [])
      const {loading, planList} = plans;
  return (
    <div id="page-content">
        <TickerTape colorTheme={currentTheme}></TickerTape>
        {loading?
        <div>
            Loading...
        </div>:

        <div>
            <div className="card card-style bg-fade-blue border border-fade-blue alert alert-dismissible show fade p-0 mb-3">
                <div className="content my-3">
                    <div className="d-flex">
                        <div className=" no-click">
                            <i className="bi bi-info-square font-33 color-blue-dark d-block"></i>
                        </div>
                        <div className=" no-click">
                            <p className="color-theme mb-0 font-500 font-14 ps-3 pe-4 line-height-s">
                                <strong>Available investment plans</strong> - Kindly select any investment plan below to get started
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="">
                <div className=" mt-0 mb-0">        
                    {planList.map(plan => (
                        <Link key={plan.id} to="#" className="pb-3 border-bottom card card-style px-0">
                            <div className="d-flex justify-content-center p-3">
                                <span className="icon icon-xxl gradient-yellow me-2 shadow-bg shadow-bg-s rounded-s">
                                    <img src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1655579457/cardrevamp.jpg" width="45" className="rounded-s" alt="img"/>
                                </span>
                            </div>
                            <h4 className="pt-1 mb-n1 color-theme text-center">{plan.name}</h4>

                            <div className="p-4">
                                <h4 className="pt-1 color-red-dark d-flex justify-content-between border-bottom border-1">
                                <span className='color-theme'>Min Amount:</span> <span className=''>{getAmount(plan.min_amount)}</span> 
                                </h4>
                                <h4 className="pt-1 color-green-light d-flex justify-content-between border-bottom border-1">
                                <span className='color-theme'>Max Amount: </span> <span className=''>{getAmount(plan.max_amount)}</span>
                                </h4>
                                <h4 className="pt-1 color-green-light d-flex justify-content-between border-bottom border-1">
                                <span className='color-theme'>Referral Bonus: </span> <span className=''>{plan.ref_bonus}%</span> 
                                </h4>
                            </div>
                            <div className="p-2 text-center">
                                <p className="mb-0 font-12">{plan.description}</p>
                                <div  onClick={()=>setCurrentPlan(plan)} data-bs-toggle="offcanvas" data-bs-target="#menu-investment" className="btn btn-full gradient-green shadow-bg shadow-bg-s mt-4">
                                    Invest now
                                </div>
                            </div>                            
                        </Link> 
                    ))}
                </div>
            </div>
        </div>
        }
    </div>
  )
}

export default Invest